<template>
  <SiteLayout :showHeaderFooter="!isEmbedded" :sideBar="true" :loading="isLoading">
    <div class="microsite-show" v-if="account">
      <div class="sidebar" :style="gradient">
        <div class="hero_logo" v-html="account.svg_logo_mark"></div>
        <div class="nav" v-if="!isEmbedded">
          <router-link :to="library_home_url">
            <BackArrow />
            <span>{{ account.name }} Home</span>
          </router-link>
        </div>
        <div class="info">
          <div class="logo" v-if="account.svg_logo" v-html="account.svg_logo"></div>
          <h1 v-else>{{ account.name }}</h1>
          <h1>{{ asset_list.title }}</h1>
          <b-button
            class="cta"
            variant="light"
            target="_blank"
            :href="asset_list.cta_url"
            v-if="asset_list.cta_url"
          >
            <b>{{ asset_list.cta_text }}&nbsp;<i class="bi bi-arrow-right" /></b
          ></b-button>
        </div>
      </div>
      <div class="content">
        <div class="mb-3">
          <div class="filters d-flex flex-row items-center">
            <div class="search searchbar" v-if="asset_list.include_search">
              <BFormInput
                v-model="q"
                placeholder="Filter by Respondent Name, Company, Testimonial, etc."
                debounce="500"
                class="search_input"
              />
            </div>
            <div class="selects d-flex flex-row">
              <BFormSelect :options="typeFilter" v-model="type" />
              <BFormSelect
                :options="the_tag_filters"
                v-model="tag"
                :disabled="the_tag_filters.length == 0"
                v-if="asset_list.include_tags"
              />
              <BFormSelect
                :options="the_sector_filters"
                v-model="sector_id"
                :disabled="sector_filters.length == 0"
                v-if="asset_list.include_industry_filters"
              />
              <BFormSelect
                :options="the_size_group_filters"
                v-model="size_group"
                :disabled="size_group_filters.length == 0"
                v-if="asset_list.include_company_size_filters"
              />
              <BFormSelect
                :options="the_seniority_filters"
                v-model="seniority"
                :disabled="seniority_filters.length == 0"
                v-if="asset_list.include_seniority_filters"
              />
              <BFormSelect
                :options="the_recipient_role_filters"
                v-model="recipient_role_id"
                :disabled="recipient_role_filters.length == 0"
                v-if="asset_list.include_role_filters"
              />
              <div class="d-flex classifiers-filters text-truncate" v-if="classifiers_ready">
                <BFormSelect
                  v-for="classifier in asset_list.classifiers"
                  :options="classifier_options[classifier.id]"
                  :key="classifier.id"
                  v-model="setting_classifier[classifier.id]"
                  @change="handleChangeClassifier"
                  :disabled="shouldDisableClassifierFilter(classifier.id)"
                />
              </div>
            </div>
          </div>
        </div>

        <header class="mb-4" v-if="asset_list.headline || asset_list.description">
          <h2>{{ asset_list.headline }}</h2>
          <p>{{ asset_list.description }}</p>
        </header>

        <div class="assets-wrapper mb-5">
          <EmbeddedAssetsGrid
            v-if="isEmbedded"
            :contentAssets="content_assets"
            :account="account"
            :assetList="asset_list"
          />
          <AssetsGrid
            v-if="!isEmbedded"
            :contentAssets="content_assets"
            :assetList="asset_list"
            :account="account"
          />
        </div>
      </div>
    </div>
  </SiteLayout>
</template>

<script>
import axios from '@lib/apiClient'
import BackArrow from '@/components/graphics/BackArrow.vue'
import filters from '@mixins/filters.js'
import url_manager from '@mixins/url_manager'
import AssetsGrid from './AssetsGrid.vue'
import EmbeddedAssetsGrid from './EmbeddedAssetsGrid.vue'
import { brandLinkColor, headerColor } from '@lib/colors'
import { useAccountStore } from '@app/stores/AccountStore'
import SiteLayout from '@site/components/SiteLayout.vue'

export default {
  components: {
    BackArrow,
    AssetsGrid,
    EmbeddedAssetsGrid,
    SiteLayout,
  },
  props: {
    isEmbedded: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [filters, url_manager],
  data() {
    return {
      account: null,
      asset_list: null,
      asset_list_items: null,
      count: null,
      total_count: null,
      typeFilter: [
        { value: '', text: 'All Asset Types' },
        { value: 'ChartAsset', text: 'Charts' },
        { value: 'CustomerSpotlight', text: 'Customer Spotlights' },
        { value: 'RenderableTestimonial', text: 'Testimonials' },
        { value: 'StatAsset', text: 'Stats' },
        { value: 'MatrixxChart', text: 'Matrix charts' },
        { value: 'SpotlightAsset', text: 'Reports' },
        { value: 'VideoLinkAsset', text: 'Video Link Assets' },
        { value: 'FileAsset', text: 'PDF Assets' },
      ],
      setting_classifier: {},
      classifier_options: {},
      classifiers_ready: false,
      loading: false,
    }
  },
  head() {
    return {
      title: `${this.account.name} UserEvidence Research Library | UserEvidence`,
      meta: [{ hid: 'theme-color', name: 'theme-color', content: this.account?.brand_color_1 }],
    }
  },
  computed: {
    brandLinkColor,
    gradient() {
      const backgroundColor = this.headerColor
      return `background: radial-gradient(134.88% 877.05% at 15.14% -17.75%, ${backgroundColor} 0%, ${backgroundColor} 100%), no-repeat`
    },
    content_assets() {
      return this.asset_list_items.map((i) => i.content_asset)
    },
    library_home_url() {
      return `/user-research-library/${this.account.slug}`
    },
    filter_url() {
      return `/api/accounts/${this.account.slug}/asset_filters.json?asset_list_id=${this.asset_list.id}`
    },
    isLoading() {
      return this.loading
    },
    headerColor,
  },
  watch: {
    all_watchers() {
      this.getTheData()
    },
  },
  mounted() {
    if (this.$cookies) {
      this.$cookies.remove('site-back-url')
      this.$cookies.remove('site-back-text')
    }

    this.getTheData()

    // we are doing this here to get the app json for koala tracking.
    // note this request will fail if the user is not logged in via the app.
    // be aware that we'll be seeing 401's in the logs because of this
    useAccountStore().getApp()
  },
  methods: {
    shouldDisableClassifierFilter(classifierId) {
      return (
        this.classifier_options[classifierId] && this.classifier_options[classifierId].length <= 1
      )
    },
    handleChangeClassifier() {
      // This is a little handler that is used to add the classifier_value_ids to the
      // URL query string.
      //
      // We can't use a simple variable in url_manager to store the selected
      // classifier values, because the classifier values are created dynamically.
      //
      // Here we copy over the array values from this.setting_classifier
      // To the data attribute `classifier_value_ids` (from url_manager) that then
      // gets copied to the URL query string
      const tmp_value_ids = []
      Object.values(this.setting_classifier).map((v) => {
        if (v) tmp_value_ids.push(v)
      })
      this.classifier_value_ids = tmp_value_ids.join(',')
    },
    getAssetList(asset_list_id) {
      if (this.$route.params.token)
        Object.assign(this.the_query, { token: this.$route.params.token })

      return axios
        .get(`/api/asset_lists/${asset_list_id}.json`, { params: this.the_query })
        .then((response) => {
          this.account = response.data.account
          this.asset_list = response.data.asset_list
          this.asset_list_items = response.data.asset_list_items
        })
        .catch((e) => {
          this.errors = e
        })
    },
    getFilters() {
      return axios.get(this.filter_url, { params: this.the_query }).then((r) => {
        this.size_group_filters = r.data.size_group_filters
        this.sector_filters = r.data.sector_filters
        this.seniority_filters = r.data.seniority_filters
        this.recipient_role_filters = r.data.recipient_role_filters
        this.tag_filters = r.data.tag_filters
        this.classifier_filters = r.data.classifier_filters

        if (
          this.classifier_filters &&
          this.classifier_filters.length > 0 &&
          this.asset_list.available_classifiers &&
          this.asset_list.available_classifiers.length > 0
        ) {
          // Loop through `asset_list.classifiers` since
          // these are the classifiers that were selected by the user to be displayed
          this.asset_list.classifiers.map((c) => {
            // Here we setup the possible options for the classifier dropdown
            const vals = this.getClassifierValuesByClassifierId(c.id)
            this.classifier_options[c.id] = [{ value: null, text: c.title }, ...vals]

            // These are used as props for temp storing the selected classifier values
            if (!this.setting_classifier[c.id]) this.setting_classifier[c.id] = null
          })
          // flag used to show the classifier dropdowns only when everything is properly loaded
          this.classifiers_ready = true
        }
      })
    },
    getTheData() {
      this.loading = true
      this.$router.push({ query: Object.assign({}, this.the_query) })
      this.getAssetList(this.$route.params.slug)
        .then(() => {
          return this.getFilters()
        })
        .finally(() => {
          this.loading = false
        })
    },
    // Gets all classifiers values from classifier_filters array
    // with the corresponding classifier_id
    getClassifierValuesByClassifierId(classifier_id) {
      return this.classifier_filters
        .map((c) => {
          if (c.classifier_id == classifier_id) {
            return {
              value: c.id,
              text: `${c.name} (${c.count})`,
            }
          }
        })
        .filter((item) => !!item)
    },
  },
}
</script>
<style lang="sass" scoped>
.microsite-show
  display: flex

.filters
  gap: 0.5em
  .search
    width: 25%
  .selects
    flex-wrap: wrap
    gap: 0.5em
  .classifiers-filters
    gap: 0.5em

.sidebar
  position: fixed
  top: 0
  height: 100%
  width: 25%
  .nav
    position: absolute
    top: 100px
    color: white
    a
      font-weight: 700
      margin-left: 30px
      opacity: 0.9
      font-size: 14px
      &:hover
        color: white
        svg
          opacity: 1
      svg
        opacity: 0.5
        margin-right: 8px
  .hero_logo
    opacity: 0.25
    position: absolute
    left: -32px
    top: 150px
    ::v-deep svg
      width: 256px
      height: 256px
      path
        fill: white
  .info
    height: 100%
    padding: 32px
    display: flex
    flex-direction: column
    justify-content: center
    h1
      font-weight: 800
      color: white
      font-size: 33px
      line-height: 40px
      letter-spacing: -0.02em
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.16)
.content
  margin-left: 25%
  padding: 32px
  width: 75%
.logo
  ::v-deep svg
    path
      fill: white

.selects
  width: 100%
  select
    width: 10em

@media (max-width: 960px)
  .nav
    position: relative !important
    margin-top: 48px
  .hero_logo
    position: relative !important
    top: 0px !important
    left: 0px !important
    ::v-deep svg
      position: absolute
      right: 0px
      top: 0px
      width: 156px !important
      height: 156px !important

  .filters
    display: block
    .search
      width: 100%
      margin-bottom: 5px
    .selects
      width: 100% !important
      select
        width: 100%
        margin: 0px 0px 5px 0px

  .microsite-show
    display: block
  .assets
    columns: 1
  .asset
    display: inline-block
    margin-bottom: 24px

  .sidebar
    position: relative
    height: auto
    width: 100% !important
  .content
    width: 100%

.filters select:not(:last-child)
  margin-right: 0

.cta
  color: v-bind("brandLinkColor")
</style>
