<template lang="pug">
  Show(v-if='content_asset' :content_asset="content_asset")
</template>

<script lang="ts">
import axios from '@lib/apiClient'
import Show from './Show.vue'

export default {
  components: { Show },
  data() {
    return {
      content_asset: null,
    }
  },
  mounted() {
    axios
      .get(`/api/content_assets/${this.$route.params.id}.json`)
      .then((r) => (this.content_asset = r.data.content_asset))
  },
}
</script>
