<template>
  <div class="public-asset pdf-asset" v-if="fileAsset" :class="{ horizontal: horizontal }">
    <ReportHeader
      :account="account"
      :contentAsset="fileAsset"
      :backUrl="backUrl"
      :backText="backText"
    ></ReportHeader>

    <div class="content">
      <div class="content_container d-flex flex-column mt-2 mb-2" v-if="fileAsset">
        <h2>{{ fileAsset.title }}</h2>
        <span class="mt-2">{{ fileAsset.description }}</span>
        <div class="iframe-wrapper mt-2 mb-4">
          <PdfViewer v-if="fileAsset" :file-asset="fileAsset" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ReportHeader from './basic_asset_parts/reports/ReportHeader.vue'
import PdfViewer from '@components/PdfViewer.vue'

export default {
  components: {
    ReportHeader,
    PdfViewer,
  },
  data() {
    return {
      localBlobUrl: null,
    }
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    fileAsset: {
      type: Object,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backUrl() {
      return this.$cookies.get('site-back-url') && this.$cookies.get('site-back-url').length > 5
        ? this.$cookies.get('site-back-url')
        : `/user-research-library/${this.account.slug}`
    },
    backText() {
      return this.$cookies.get('site-back-text') && this.$cookies.get('site-back-text').length > 5
        ? this.$cookies.get('site-back-text')
        : `${this.account.name} Home`
    },
  },
}
</script>
<style lang="sass">
.pdf-asset
  .iframe-wrapper
    height: 100vh
</style>
