<template>
  <div v-if="contentAssets.length > 0 && account" class="assets">
    <div v-for="asset in contentAssets" :key="asset.identifier" class="asset">
      <GridAssetPresenter
        :asset="asset"
        :account="account"
        @click.native="handleAssetClicked(asset)"
      />
    </div>
  </div>
  <div v-else class="assets none">
    <h3>No assets match those filters.</h3>
  </div>
</template>

<script>
import GridAssetPresenter from '@site/components/asset_lists/GridAssetPresenter.vue'

export default {
  components: {
    GridAssetPresenter,
  },
  props: {
    contentAssets: {
      type: Array,
      default: () => [],
    },
    account: {
      type: Object,
      required: true,
    },
    assetList: {
      type: Object,
    },
  },
  methods: {
    assetUrl(asset) {
      return `/assets/${asset.identifier}`
    },
    handleAssetClicked(asset) {
      this.$router.push(this.assetUrl(asset))
    },
    setCookie() {
      this.$cookies.set('site-back-url', this.$router.history.current.fullPath)
      this.$cookies.set('site-back-text', `Microsite: ${this.asset_list.title}`)
    },
  },
}
</script>
<style lang="sass" scoped>
.assets
  columns: 2
.assets.none
  min-height: 35vh
.asset
  display: inline-block
  margin-bottom: 24px
  width: 100% !important
.asset:hover
  cursor: pointer
</style>
